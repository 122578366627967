<template>
	<div class="data-list list-tab-show-page">
		<div class="row-space-tbf">
			<div class="space-left"></div>
			<div class="content">
				<template v-if="soft_organigram.is_active">
					<calendar ref="calendarComponent" :data="{slug: data.slug, id: data.id, right: data.right, from: data.from, viewToolbar: true, filtersData: data.filters}"/>
				</template>
				<not-module class="with-border-top" v-else/>
			</div>
			<div class="space-right"></div>
		</div>
	</div>
</template>

<script>
	import Calendar from '../Calendar.vue'
	import NotModule from '@/components/General/NotModule'

	export default {
		components: {
			Calendar,
			NotModule
		},
		props: {
			data: Object
		},
		computed: {
			soft_organigram() {
				return this.$store.getters['applications/getApplication']('Organigrama');
			}
		},
		data() {
			return {
				
			}
		},
		mounted() {

		},
		methods: {
			
		}
}
</script>